<template>
  <div class="create-content">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
      <el-form-item label="创建名称" prop="store_registration_name">
        <el-input v-model="ruleForm.store_registration_name" placeholder="1-30个字" clearable style="width: 500px"></el-input>
      </el-form-item>
      <el-form-item label="行业分类" prop="category_id">
        <el-select v-model="ruleForm.category_id" placeholder="请选择行业分类" clearable style="width: 500px">
          <el-option v-for="cateItem in categoryList" :key="cateItem.id" :label="cateItem.name" :value="cateItem.id"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="上传素材" prop="material_file.url">
        <el-upload
            :action="uploadUrl"
            :headers="headersToken"
            :on-success="handleCoverSuccess"
            :before-upload="beforeCoverUpload"
            :on-change="handleChange"
            :show-file-list="false"
            accept=".zip,.rar"
            name="file">
          <el-button>选择文件</el-button>
        </el-upload>
        <template v-if="ruleForm.material_file.url">
          <span style="margin-left: 10px">{{ruleForm.material_file.name}}</span>
          <i @click="delFileBtn" style="color: #ff0000; margin-left: 10px; font-size: 16px; cursor: pointer" class="el-icon-error"></i>
        </template>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  props: {
    ruleForm: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      fileName: '',
      rules: {
        store_registration_name: [
          { required: true, message: '请输入名称', trigger: 'blur' },
          { min: 1, max: 30, message: '长度在 1 到 30 个字', trigger: 'blur' }
        ],
        category_id: [
          { required: true, message: '请选择行业分类', trigger: 'change' }
        ],
        'material_file.url': [
          { required: true, message: '请选择素材', trigger: 'change' }
        ],
      },
      // start 上传文件所需
      headersToken: {
        Authorization: localStorage.getItem('token')
      },
      uploadUrl: this.$api.storeSettingsUploadMaterial,
      categoryList: [],
    }
  },
  created() {
    this.getCategoryList()
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs['ruleForm'].clearValidate()
    })
  },
  methods: {
    // 获取行业分类列表
    getCategoryList() {
      this.$http.axiosGet(this.$api.categoryList, (res) => {
        if (res.code === 200) {
          this.categoryList = res.data
        } else {
          this.$message.warning(res.msg)
        }
      }, (err) => {
        console.log(err)
      })
    },
    beforeCoverUpload(file) {
      const isLt10M = file.size / 1024 / 1024 / 1024 <= 1;
      if (!isLt10M) {
        this.$message.warning('支持rar、zip格式，文件不超过1G!');
        return false;
      }
    },
    handleCoverSuccess(res, file, fileList) {
      if (res.code === 200) {
        this.ruleForm.material_file.name = file.name
        this.ruleForm.material_file.url = res.data
        this.$message.success(res.msg)
      } else {
        this.$message.error('文件上传失败，请稍后再试~')
      }
    },
    handleChange(uploadFile, uploadFiles) {
      if (this.ruleForm.material_file.url) {
        this.$refs.ruleForm.validateField('material_file.url')
      }
    },
    delFileBtn() {
      this.ruleForm.material_file.name = ''
      this.ruleForm.material_file.url = ''
    }
  }
}
</script>

<style scoped lang="scss">
.create-content {
  margin: 60px 0;
}
</style>