<template>
  <el-scrollbar class="education-wrapper" :native="false">
    <div class="total-score">
      <el-input v-model="scoreTotal" placeholder="总分" disabled style="width: 100px"></el-input>
    </div>
    <div class="steps-wrapper">
      <div class="step-item" :class="{current: [1, 2, 3].includes(stepActive)}">
        <div class="step-line"></div>
        <div class="step-text">1</div>
        <div class="step-title">创建店铺注册数据</div>
      </div>
      <div class="step-item" :class="{current: [2, 3].includes(stepActive)}">
        <div class="step-line"></div>
        <div class="step-text">2</div>
        <div class="step-title">添加店铺注册数据</div>
      </div>
      <div class="step-item" :class="{current: [3].includes(stepActive)}">
        <div class="step-line"></div>
        <div class="step-text">3</div>
        <div class="step-title">创建成功</div>
      </div>
    </div>
    <StepOne ref="stepOneRef" v-if="stepActive === 1" :ruleForm.sync="ruleForm" />
    <StepTwo ref="stepTwoRef" v-if="stepActive === 2" :ruleForm.sync="ruleForm" />
    <div class="create-content" v-if="stepActive === 3">
      <div class="success-content">
        <i class="iconfont">&#xe730;</i>
        <p class="text">创建成功！</p>
      </div>
    </div>
    <div class="bottom-btn">
      <el-button @click="prevBtn(stepActive)" class="btn-blue-line-all" v-if="stepActive === 1 || stepActive === 2">上一步</el-button>
      <el-button @click="prevBtn(stepActive)" class="btn-blue-line-all" v-if="stepActive === 3">返回</el-button>
      <el-button class="btn-blue" @click="nextBtn(stepActive)"  v-if="stepActive === 1">下一步</el-button>
      <el-button class="btn-blue" @click="nextBtn(stepActive)"  v-if="stepActive === 2">下一步</el-button>
    </div>
  </el-scrollbar>
</template>

<script>
import StepOne from "./component/StepOne";
import StepTwo from "./component/StepTwo";

export default {
  data() {
    return {
      stepActive: 2, // 步骤
      ruleForm: {
        store_registration_name: '',
        category_id: null,
        material_file: {
          name: '',
          url: ''
        },

        contact_name: '',
        contact_name_score: void 0,
        contact_phone: '',
        contact_phone_score: void 0,
        contact_email: '',
        contact_email_score: void 0,
        is_abutting_joint_JD: 1, // 是否对接京东招商人员 0:无 1:有
        is_abutting_joint_score: void 0,
        name: '',
        name_score: void 0,
        recommend_code: '',
        recommend_code_score: void 0,
        document_type: '', // 证件类型 0:多证合一营业执照（统一社会信用代码） 1:多证合一营业执照（非统一社会信用代码）
        document_type_score: void 0,
        businessLicense_score: void 0,
        corporate_name: '',
        corporate_name_score: void 0,
        unified_social_credit_code: void 0,
        unified_social_credit_code_score: void 0,
        business_license_location: [],
        business_license_location_province: null,
        business_license_location_city: null,
        business_license_location_area: null,
        business_license_location_score: void 0,
        business_license_address: null,
        business_license_address_score: void 0,
        incorporation_date: null,
        incorporation_date_score: void 0,
        business_date_begin: null,
        business_date_end: null,
        business_date_type: 0, // 营业日期类型 0:默认 1:长期
        business_date_score: void 0,
        registered_capital: void 0,
        registered_capital_score: void 0,
        business_scope: '',
        business_scope_score: void 0,
        legal_representative_certificate_type: null, // 法定代表人证件类型 0:大陆身份证 1:护照 2:港澳居民通行证 3:台湾居民通行证
        legal_representative_certificate_type_score: void 0,
        legal_person__document_electron_score: void 0,
        legal_representative_name: '',
        legal_representative_name_score: void 0,
        legal_representative_certificates_num: null,
        legal_representative_certificates_num_score: void 0,
        alidity_period_begin: null,
        alidity_period_end: null,
        alidity_period_type: null, // 有效期类型 0:默认 1:长期
        alidity_period_score: void 0,
        taxpayers_type: null, // 纳税人类型 0:一般纳税人 1:小规模纳税人 2非增值税纳税人
        taxpayers_type_score: void 0,
        taxpayers_identity_num: null,
        taxpayers_identity_num_score: void 0,
        yax_type: null, // 纳税类型 0:0% 1:1% 2:3% 3:6% 4:7% 5:9% 6:10% 7:13% 8:图书9%免税
        yax_type_score: void 0,
        tax_registration_score: void 0,
        taxpayer_qualification_certificate_score: void 0,
        corporate_settlement_bank_account: null,
        corporate_settlement_bank_account_score: void 0,
        branch_bank_account: null,
        branch_bank_account_score: void 0,
        branch_bank_name: null,
        branch_bank_name_score: void 0,
        open_account_bank: [],
        open_account_bank_province: null,
        open_account_bank_cit: null,
        open_account_bank_area: null,
        open_account_bank_score: void 0,
        company_type: null, // 公司类型 0:生产厂商 1:品牌商 2:代理商 3:经销商
        company_type_score: void 0,
        company_web: null,
        company_web_score: void 0,
        annual_sales: void 0,
        annual_sales_score: void 0,
        goods_num: null, // 商品数量 0:0-100 1:100-200 2:200-500 3:大于500
        goods_num_score: void 0,
        avg_price: null, // 平均客单价 0:0-100 1:100-200 2:200-500 3:大于500
        avg_price_score: void 0,
        warehouse_situation: null, // 仓库情况 0:自有仓库 1:第三方仓库 2:无仓库
        warehouse_situation_score: void 0,
        warehouse_address: null,
        warehouse_address_score: void 0,
        common_logistics: '',
        common_logistics_score: void 0,
        ERP_type: null, // ERP类型 0:自有ERP 1:第三方ERP代运营 2:无
        ERP_type_score: void 0,
        agent_operating_company: null,
        agent_operating_company_score: void 0,
        store_type: null, // 店铺类型 0:旗舰店 1:专营店 2:专卖店
        store_type_score: void 0,
        good_category_id: [],
        good_category_score: void 0,
        company_abbreviation: '',
        company_abbreviation_score: void 0,
        category_description: '',
        category_description_score: void 0,
        shop_suffix: null, // 店铺后缀 0:专营店 1:专卖店 2:旗舰店 3:官方旗舰店
        shop_suffix_score: void 0,
      }
    }
  },
  components: {
    StepOne,
    StepTwo
  },
  created() {
    if (this.$route.query.id) {
      this.getInfo()
    }
  },
  computed: {
    scoreTotal() {
      let total = 0
      for (const key in this.ruleForm) {
        let item = this.ruleForm[key]
        if (key.includes('_score')) {
          if (isNaN(Number(item))) {
            total += 0
          } else {
            total += Number(item)
          }
        }
      }
      return total
    }
  },
  methods: {
    getInfo() {
      let params = {
        id: this.$route.query.id
      }
      this.$http.axiosGetBy(this.$api.storeSettingsDetail, params, (res) => {
        if (res.code === 200) {
          this.ruleForm = {...this.ruleForm, ...res.data}
          this.ruleForm.material_file = res.data.material_file
          this.ruleForm.shop_suffix = Number(res.data.shop_suffix)

          this.ruleForm.incorporation_date = res.data.incorporation_date * 1000
          this.ruleForm.business_date_begin = res.data.business_date_begin * 1000
          this.ruleForm.business_date_end = res.data.business_date_end * 1000
          this.ruleForm.alidity_period_begin = res.data.alidity_period_begin * 1000
          this.ruleForm.alidity_period_end = res.data.alidity_period_end * 1000

          this.ruleForm.business_license_location = [res.data.business_license_location_province, res.data.business_license_location_city, res.data.business_license_location_area]
          this.ruleForm.open_account_bank = [res.data.open_account_bank_province, res.data.open_account_bank_cit, res.data.open_account_bank_area]
        } else {
          this.$message.warning(res.msg)
        }
      }, (err) => {
        console.log(err)
      })
    },
    // 上一步按钮
    prevBtn(index) {
      if (index === 1) {
        this.$router.go(-1)
      } else if (index === 2) {
        this.stepActive = 1
      } else if (index === 3) {
        this.$router.push('/admin/registerData')
      }
    },
    // 下一步按钮
    nextBtn(index) {
      // 第一步的下一步
      if (index === 1) {
        this.$refs.stepOneRef.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.stepActive = 2
          } else {
            return false;
          }
        });
      } else if (index === 2) {
        if (this.scoreTotal !== 100) {
          return this.$message.warning('总分应为100')
        }

        let obj = JSON.parse(JSON.stringify(this.ruleForm))
        delete obj.business_license_location
        delete obj.open_account_bank
        obj.business_license_location_province = this.ruleForm.business_license_location[0]
        obj.business_license_location_city = this.ruleForm.business_license_location[1]
        obj.business_license_location_area = this.ruleForm.business_license_location[2]
        obj.open_account_bank_province = this.ruleForm.open_account_bank[0]
        obj.open_account_bank_cit = this.ruleForm.open_account_bank[1]
        obj.open_account_bank_area = this.ruleForm.open_account_bank[2]

        obj.incorporation_date = this.ruleForm.incorporation_date / 1000
        obj.business_date_begin = this.ruleForm.business_date_begin / 1000
        obj.business_date_end = this.ruleForm.business_date_end / 1000
        obj.alidity_period_begin = this.ruleForm.alidity_period_begin / 1000
        obj.alidity_period_end = this.ruleForm.alidity_period_end / 1000

        if (this.ruleForm.business_date_type) {
          obj.business_date_type = 1
        } else {
          obj.business_date_type = 0
        }
        if (this.ruleForm.alidity_period_type) {
          obj.alidity_period_type = 1
        } else {
          obj.alidity_period_type = 0
        }
        obj.good_category_id = this.ruleForm.good_category_id.at(-1)
        if (this.$route.query.id) {
          obj.id = this.$route.query.id
        }

        this.$refs.stepTwoRef.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.$http.axiosPost(this.$api.storeSettingsSave, obj, (res) => {
              if (res.code === 200) {
                this.$message.success(res.msg)
                this.stepActive = 3
              } else {
                this.$message.error(res.msg)
              }
            }, (err) => {
              console.log(err)
            })
          } else {
            return false;
          }
        });
      }
    }
  }
}
</script>

<style scoped lang="scss">
.education-wrapper {
  height: 100%;
  ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
    .el-scrollbar__view {
      padding: 60px;
    }
  }
}
.bottom-btn {
  text-align: center;
}
.success-content {
  margin-top: 155px;
  text-align: center;
  .iconfont {
    color: #1FD18C;
    font-size: 52px;
  }
  .text {
    font-size: 24px;
    margin: 20px 0 0;
    font-weight: bold;
  }
}
.total-score {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>